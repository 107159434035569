<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      no-close-on-backdrop
      hide-footer
      :title="$t('confirmationDialog.confirmation')"
    >
      <div class="d-block text-center">
        
<svg class="icon-warning" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0001 29.3334C23.3639 29.3334 29.3334 23.3639 29.3334 16.0001C29.3334 8.63628 23.3639 2.66675 16.0001 2.66675C8.63628 2.66675 2.66675 8.63628 2.66675 16.0001C2.66675 23.3639 8.63628 29.3334 16.0001 29.3334Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.6787 12H10.6667M21.3334 12H21.3214M13 18.6667H20.0001" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        <h4>{{ $t("confirmationDialog.change_menu") }}</h4>
        <b-button class="mt-4 btn custom-btn pattern-btn" @click="$emit('close')"><span>{{
          $t("confirmationDialog.cancel")
        }}</span></b-button>
        <b-button
          class="mt-4 ml-2 btn custom-btn custom-danger"
          variant="outline-danger"
          @click="$emit('confirm')"
          >{{ $t("confirmationDialog.yes") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    }
  }
};
</script>
